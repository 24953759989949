<template>

    <div class="w-full h-full px-3 py-2 relative overflow-hidden" :class="'bg-bg-'+mode">

        <div class="absolute top-0 left-0 w-full h-20 z-20" 
        :class="{'-mt-6': isVisible || searchInput, '-mt-20': !isVisible && !searchInput}"
        style="transition: all .3s">
            <div class="h-full w-full flex flex-col justify-end items-center p-2" :class="'bg-module-'+mode">
                <input type="text" v-model="searchInput" :placeholder="$t('buscarusuario')" class="w-full h-10 px-2 rounded-xl" :class="'bg-box-'+mode+' text-dfont-'+mode">
            </div>
        </div>

        <div class="h-full w-full flex flex-col">

            <!-- <div class="h-full flex flex-col pb-6 rounded-lg shadow-card p-2 mx-1 overflow-hidden" :class="'bg-module-'+mode"> -->

                <!-- <div class="h-12 w-full flex flex-row">

                    <div class="h-full w-full py-1 px-2 flex flex-row justify-between items-center">

                        <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{$t('users')}}</span>

                        <div class="h-auto py-1 px-3 bg-good rounded-lg shadow flex flex-row justify-center items-center" @click="createuser()">
                            <span class="text-white text-xs">{{$t('createUser')}}</span>
                        </div>

                    </div>

                </div> -->

                <div class="absolute bottom-0 right-0 h-auto flex w-auto z-30 m-2" >

                    <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="download()">
                        <i class="mdi mdi-download-outline text-white text-xl font-semibold"></i>
                    </div>

                    <div class="h-10 w-10 mx-2 rounded-full bg-red flex flex-row justify-center items-center" @click="openfiltersearch = true">
                        <i class="mdi mdi-filter text-white text-xl font-semibold"></i>
                    </div>
                    
                    <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="createuser()">
                        <i class="mdi mdi-plus text-white text-xl font-semibold"></i>
                    </div>

                </div>

                <!-- <div class="h-10 flex flex-row">

                    <div class="h-full flex flex-row justify-start items-center px-2 border-b border-darkgray mb-6" :class="{'w-8/12': searchInput != '', 'w-9/12':searchInput == '', 'bg-box-darkmode': mode == 'darkmode', 'bg-box-whitemode': mode == 'whitemode'}">
                        <i class="mdi mdi-magnify text-gray mr-2"></i>
                        <input type="text" v-model="searchInput" class="w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                    </div>

                    <div v-if="searchInput != ''" class="h-full w-1/12 flex flex-col justify-center items-center" :class="'bg-box-'+mode" @click="searchInput = ''">
                    
                        <i class="mdi  mdi-close text-red"></i>

                    </div>

                    <div class="h-full w-3/12 flex flex-row justify-end items-center">

                        <div class="h-8 w-8 rounded-lg shadow flex flex-col justify-center items-center mr-2" :class="'bg-box-'+mode" @click="openfiltersearch = true">

                            <i class="mdi mdi-filter font-semibold" :class="'text-dfont-'+mode"></i>

                        </div>

                        <All model="downloadpdf" :immediate="false" :data.sync="excel" v-slot="{ request, loading:loadingrequest }" ref="excel">

                            <div class="h-8 w-8 rounded-lg shadow flex flex-col justify-center items-center" :class="'bg-box-'+mode" @click="download()">

                                <i class="mdi mdi-download-outline font-semibold" :class="'text-dfont-'+mode"></i>

                            </div>

                        </All>
                    
                    </div>

                </div> -->

                <!-- <div class="flex-1 overflow-hidden"> -->

                    <All model="UsersApp" :immediate="true" ref="users" v-slot="{data,loading}" :data.sync="users">

                        <div v-if="!loading" class="h-full w-full overflow-auto pb-12" :class="{'pt-12': searchInput != ''}">

                            <Intersect @enter="onEnter" @leave="onLeave" >
                                <div class="h-1 w-full"></div>
                            </Intersect>

                            <user v-for="(el,index) in filteredUsers(data)" :key="index" :data="el" @reload="reload" class="rounded-lg shadow_box" :class="'bg-box-'+mode"></user>

                            <div v-if="filteredUsers(data).length == 0"  class="h-full flex flex-col justify-center items-center">

                                <span class="text-dfont">No hay datos</span>

                            </div>
                            

                        </div>

                        <div v-else class="h-20 relative">
                            <loader :loading="loading"></loader>
                        </div>

                    </All>

                <!-- </div> -->
                
            <!-- </div> -->

        </div>

        <div class="absolute bottom-0 left-0 z-50 h-76 w-full p-2" :class="{'-mb-192': !openfiltersearch, 'mb-0': openfiltersearch}" style="transition: all .3s;">

            <div class="h-full w-full rounded-lg shadow-card bg-white" :class="'bg-module-'+mode">

                <div class="h-full w-full p-2 flex flex-col">

                    <div class="h-10 flex flex-row justify-between items-center px-2">
                    
                        <span class="text-xl font-semibold" :class="'text-dfont-'+mode">{{$t('filters')}}</span>
                        <i class="mdi mdi-close font-semibold" :class="'text-dfont-'+mode" @click="openfiltersearch = false"></i>
                        
                    </div>

                    <div class="flex-1 min-h-0 flex flex-col">

                        <div class="h-6">
                            <span class="text-xs" :class="'text-dfont-'+mode">Buscar por:</span>
                        </div>

                        <div class="h-8 w-full mb-2">

                            <div class="h-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">
                                <!-- <el-select v-model="optiontofilter" :placeholder="$t('select')" class="w-full">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"/>
                                </el-select> -->
                                <select name="optiontofilter" v-model="optiontofilter" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                    <option v-for="(el,index) in options" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                                </select>
                            </div>

                        </div>

                        <div class="h-auto">

                            <All model="BackCompany" :immediate="true" :query="querycompanies" v-slot="{data:datacompany,loading:loadingcompany}">

                                <div v-if="!loadingcompany" class="h-auto w-full">

                                    <span class="text-xs" :class="'text-dfont-'+mode">{{$t('company')}}:</span>

                                    <div class="h-8 w-full">

                                        <div class="h-full w-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">

                                            <!-- <el-select v-model="companyselected2" filterable :placeholder="$t('selectACompany')" class="w-full text-dfont">

                                                <el-option
                                                v-for="item in datacompany"
                                                :key="item.Id"
                                                :label="item.FrontName"
                                                :value="item.Id"
                                                class="w-full text-dfont">
                                                </el-option>

                                                <el-option
                                                label="TODAS"
                                                :value="null"
                                                class="w-full text-dfont">
                                                </el-option>

                                            </el-select> -->

                                            <select name="companyselected2" v-model="companyselected2" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                                <option v-for="(el,index) in datacompany" :key="index" :value="el.Id" :class="'text-dfont-'+mode">{{el.FrontName}}</option>
                                                <option :value="null">TODAS</option>
                                            </select>

                                        </div>

                                    </div>

                                </div>

                            </All>

                        </div>

                        <div class="h-6">
                            <span class="text-xs" :class="'text-dfont-'+mode">Rol:</span>
                        </div>    
                        
                        <div class="h-8 w-full mb-2">

                            <div class="h-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">
                                <!-- <el-select v-model="rolselected2" :placeholder="$t('select')" class="w-full">
                                    <el-option
                                        v-for="item in rols_select"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"/>
                                </el-select> -->
                                <select name="rolselected2" v-model="rolselected2" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                    <option v-for="(el,index) in rols_select" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{el.name}}</option>
                                </select>
                            </div>

                        </div>               

                        <div class="h-8 mt-auto">
                            <div class="h-full w-8/12 mx-auto rounded-lg bg-red flex flex-col justify-center items-center"  @click="apply()">
                                <span class="text-white text-xs font-semibold">Aplicar</span>
                            </div>
                        </div>

                    </div>
                    

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import basiclinechart from '../../components/basiclinechart.vue';
import { All, Request } from '@/api/components';
import { state, actions } from '@/store';
import user from '../../components/user.vue';
import loader from '../../components/loader.vue';
import XlsExport from 'xlsexport';
import Intersect from 'vue-intersect';

export default {
    components:{
        basiclinechart,
        All,
        Request,
        user,
        loader,
        XlsExport,
        Intersect
    },
    data(){
        return{
            form: {
                username: null,
                email: null,
                password: null,
                password2: null,
                rol: null
            },
            rols:[
                'Propiedad',
                'Gerente',
                'Comercial',
                'Representante'
            ],
            rols_select:[
                {name:'TODOS',value:null},
                {name:'Propiedad',value:1},
                {name:'Gerente',value:2},
                {name:'Responsable de Zona',value:3},
                {name:'Representante',value:4}
            ],
            searchInput: '',
            openfiltersearch: false,
            optiontofilter:'fullName',
            options:[{name:'Nombre', value: 'fullName'}, {name:'Email', value: 'email'}],
            companyselected:null,
            companyselected2:null,
            users:[],
            excel:false,
            rolselected2:null,
            rolselected:null,
            isVisible: false
        }
    },
    methods:{
        onLeave(){
            this.isVisible = true
        },
        onEnter(){
            this.isVisible = false
        },
        async onSuccess(){
            // this.$message({
            //     message: 'Contraseña Actualizada',
            //     type: 'success'
            // });
            // this.cambiarpass =! this.cambiarpass
            // const user = await Auth.me();
            // actions.setUser(user);

        },
        onError(){
            this.$message.error(state.messageError);
        },
        filteredUsers(data){

            if(this.companyselected != null){

                var result = [];

                let arra = Object.values(data)

                for (let index = 0; index < arra.length; index++) {

                    if(arra[index].IdCompanies){

                        var arraycompanies = arra[index].IdCompanies.split(',');

                    
                        for (let index2 = 0; index2 < arraycompanies.length; index2++) {

                            if(arraycompanies[index2].toUpperCase() == this.companyselected.toUpperCase()){

                                if(!this.rolselected){

                                    result.push(arra[index])
                                } else {

                                    if(arra[index].Level == this.rolselected){
                                        result.push(arra[index])
                                    }

                                }


                            }

                        }


                    } 
                    
                    
                }


                return result.filter(post => {
                    if(post[this.optiontofilter])
                    return post[this.optiontofilter].toLowerCase().includes(this.searchInput.toLowerCase())
                })


            } else {

                if(!this.rolselected){

                    return data.filter(post => {
                        if(post[this.optiontofilter])
                        return post[this.optiontofilter].toLowerCase().includes(this.searchInput.toLowerCase())
                    })

                } else {

                    let response = []

                    for (let index = 0; index < data.length; index++) {
                        
                        if(data[index].Level == this.rolselected){
                            response.push(data[index])
                        }
                        
                    }

                    return response

                }

            }

            
        },
        reload(){
            this.searchInput = ''
            this.$refs['users'].request();
        },
        createuser(){-
            this.$router.push( { name:'newuser' } )
        },
        apply(){

            this.companyselected = null;
            this.companyselected = this.companyselected2;
            this.openfiltersearch = false;
            this.rolselected = this.rolselected2

        },
        download(){
            // var xls = new XlsExport(this.filteredUsers(this.users));
            // // xls.exportToXLS('export2017.xls');
            // xls.exportToCSV('users.csv');

            // this.$refs['excel'].request();
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'users.xls'); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            this.loadingExcel = true
            this.axios.get('useradmin/getExcel', {
                
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/vnd.ms-excel'
                },
                params:{
                    'Company': this.companyselected
                }
            }).then(response => {
                const blob = new Blob([response.data], { type: response.data.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Users.xlsx');
                link.click();
                this.loadingExcel = false
            }).catch( error => {
                this.loadingExcel = 'error'
                setTimeout( () =>  {
                    this.loadingExcel = false
                }, 5000)
            })

        }

    },
    computed:{
        user(){
            return state.user
        },
        querycompanies(){
            return{
                period: state.period,
                Dimension: state.dimensionGroup
            }
        },
        mode(){
            return state.mode
        }
    },
}
</script>